import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const NavLink = styled(Link)`
  padding: 20px;
  color: pink;
  text-decoration: none;
`

const Header = ({ siteTitle }) => (
  <div
    style={{
      marginBottom: '1.45rem',
    }}
  >
    <header
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '1.45rem 1.0875rem',
      }}
    >
      <Link
        style={{
          position: 'fixed',
          top: '20px',
          left: '20px',
          border: '1px solid pink',
          textDecoration: 'none',
          color: 'pink',
          padding: '5px',
        }}
        to="/"
      >
        Mr.T
      </Link>
      <nav>
        <ul
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            listStyle: 'none',
          }}
        >
          {/* <li
            style={{
              border: '1px solid pink',
              marginRight: '2%',
            }}
          >
            <NavLink to="/about">about</NavLink>
          </li> */}
          <li
            style={{
              border: '1px solid pink',
              marginRight: '2%',
            }}
          >
            <NavLink to="/blog">blog</NavLink>
          </li>
          <li style={{ border: '1px solid pink' }}>
            <NavLink to="/contact">contact</NavLink>
          </li>
        </ul>
      </nav>
    </header>
  </div>
)

export default Header
